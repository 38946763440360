// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuell-js": () => import("./../../../src/pages/aktuell.js" /* webpackChunkName: "component---src-pages-aktuell-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-booking-summary-js": () => import("./../../../src/pages/booking-summary.js" /* webpackChunkName: "component---src-pages-booking-summary-js" */),
  "component---src-pages-dr-lachat-js": () => import("./../../../src/pages/dr-lachat.js" /* webpackChunkName: "component---src-pages-dr-lachat-js" */),
  "component---src-pages-dr-varga-js": () => import("./../../../src/pages/dr-varga.js" /* webpackChunkName: "component---src-pages-dr-varga-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-kalender-js": () => import("./../../../src/pages/kalender.js" /* webpackChunkName: "component---src-pages-kalender-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-wiki-js": () => import("./../../../src/pages/wiki.js" /* webpackChunkName: "component---src-pages-wiki-js" */)
}

